import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/main.css'
import './assets/base.css'
import '@/assets/scss/soft-ui-dashboard.scss'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toasted from 'vue-toasted'
import './filter.ts';
import VueI18n from 'vue-i18n'
// Or, if you have to support IE9
import "@egjs/vue-flicking/dist/flicking-inline.css";
// @ts-ignore
import french from '@/internationalization/languages/fr'
import english from '@/internationalization/languages/en'


Vue.use(BootstrapVue)

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
  singleton: true
})
Vue.use(VueI18n)

const fallbackLanguage = 'fr'
const browserLanguage = 'fr'
export const i18n = new VueI18n({
  locale: browserLanguage,
  fallbackLocale: fallbackLanguage,
  messages: { fr: french , en: english }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')



