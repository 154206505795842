import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})
Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: {
        counter: 0
    },
    modules: {
    }
})
