import Vue from 'vue'
import VueRouter from 'vue-router'
import GoogleAuthenticate from '@/views/Googleauth.vue'
import GoogleGeneration from '@/views/Report/GoogleGeneration.vue'
import GoogleCallback from '@/views/GoogleCallback.vue'
import AuditGeneration from '@/views/LeadMagnet/AuditGeneration.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // @ts-ignore
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'GoogleAuthenticate',
      component: GoogleAuthenticate
    },
    {
      path: '/google-callback',
      name: 'GoogleCallback',
      component: GoogleCallback
    },
    {
      path: '/google-generation',
      name: 'GoogleGeneration',
      component: GoogleGeneration,
      props: (route) => ({
        reportId: route.query.reportId,
      }),
    },
    {
      path: '/generate-audit',
      name: 'AuditGeneration',
      component: AuditGeneration,
      props: (route) => ({
        reportId: route.query.reportId,
      }),
    }
  ]
})

export default router
