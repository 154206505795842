<template>
  <section
    :class="hideNavigation ? 'main-content-no-side-bar': ''"
    style="height: 100vh;width: 100vw;">
    <template>
      <main
        class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <!-- Navbar -->
        <nav
          v-if="!hideNavigation"
          id="navbarBlur"
          class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl">
          <div class="container-fluid py-1 px-3">
            <div
              id="navbar"
              class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4">
              <div class="ms-md-auto pe-md-3 d-flex align-items-center">
              </div>
              <ul class="navbar-nav  justify-content-end">
                <li class="nav-item d-flex align-items-center">
                  <router-link
                    :to="{ name: 'composePage', params: {} }"
                    class="btn btn-outline-primary btn-sm mb-0 me-3">
                    {{ $tc('menu.compose') }}
                  </router-link>
                </li>
                <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                  <a
                    id="iconNavbarSidenav"
                    href="javascript:;"
                    class="nav-link text-body p-0">
                    <div class="sidenav-toggler-inner">
                      <i class="sidenav-toggler-line" />
                      <i class="sidenav-toggler-line" />
                      <i class="sidenav-toggler-line" />
                    </div>
                  </a>
                </li>
                <li class="nav-item px-3 d-flex align-items-center">
                  <a
                    href="javascript:;"
                    class="nav-link text-body p-0"
                    @click="logout()">
                    <i class="fas fa-sign-out-alt cursor-pointer" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <router-view />
      </main>
    </template>
  </section>
</template>

<script>
import Vue from 'vue';
import router from '@/router/router'

export default Vue.extend({
  name: 'App',
  data: () => ({
    loading: true,
  }),
  computed: {
    hideNavigation: function() {
      return true
    }
  },
  beforeDestroy: function () {
  },
  mounted() {

  },
  methods: {
  }
});
</script>
<style lang="scss">
.main-content-no-side-bar {
  width: 100%;
}
</style>
