<template>
  <section>
    <img src="@/assets/img/pdf-2.jpg" class="d-none" id="first-image">
  </section>
</template>
<script>
// @ts-ignore
import * as pdfMake from 'pdfmake/build/pdfmake';
// @ts-ignore
import pdfFonts from './vfs_fonts.js';
pdfMake.vfs = pdfFonts;
import {getKeywordsTableContent} from "./utils/keywordTable";
import {getLastImage} from "@/views/Report/utils/general";
import {getKeywords} from "@/apis/GoogleApi";
export default {
  data() {
    return {
      pageCount: 5,
      keywordList: [],
      averageCost: 0
    };
  },
  props: {
    reportId: {
      type: String,
      required: true
    }
  },
  computed: {
    totalCost() {
      return this.keywordList.reduce((acc, keyword) => acc + keyword.cost, 0);
    },
    averageCostPerConversion() {
      let sumCostPerConversion = 0;
      let count = 0;

      this.keywordList.forEach(keyword => {
        if (keyword.conversions > 0 && keyword.cost > 0) {
          sumCostPerConversion += keyword.cost / keyword.conversions.toFixed(2);
          count++;
        }
      });
      return count ? (sumCostPerConversion / count).toFixed(2) : 0;
    },
    keywordsWithLowQuality() {
      return this.keywordList.filter(keyword => keyword.quality < 5 || keyword.quality === null);
    },
    keywordsWithoutConversionsAndHighCost() {
      return this.keywordList.filter(keyword => keyword.conversions === 0 && (keyword.cost > (this.averageCostPerConversion * 2)));
    },
    keywordsWithConversionsAndHighCost() {
      return this.keywordList.filter(keyword => keyword.conversions > 0 && (keyword.conversions / keyword.cost) > this.averageCostPerConversion * 2);
    },
    topSpendingKeywords() {
      return this.keywordList.sort((a, b) => b.cost - a.cost).slice(0, 10);
    },
    keywordsWithLowQualityTotal() {
      return this.keywordsWithLowQuality.reduce((acc, keyword) => acc + keyword.cost, 0);
    },
    keywordsWithoutConversionsAndHighCostTotal() {
      return this.keywordsWithoutConversionsAndHighCost.reduce((acc, keyword) => acc + keyword.cost, 0);
    },
    keywordsWithConversionsAndHighCostTotal() {
      return this.keywordsWithConversionsAndHighCost.reduce((acc, keyword) => acc + keyword.cost, 0);
    },
    topSpendingKeywordsTotal() {
      return this.topSpendingKeywords.reduce((acc, keyword) => acc + keyword.cost, 0);
    },
    styles() {
      return {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 3],
          color: "#E1705D",
        },
        header2: {
          fontSize: 20,
          bold: true,
          color: "#344767",
          margin: [0, 3, 0, 0],
        },
        subHeaderCost: {
          color: "white",
          fontSize: 20,
          bold: true,
          margin: [0, 10, 0 , 3],
        },
        subHeader: {
          color: "white",
          fontSize: 14,
          bold: true,
          margin: [0, 3, 0 , 10],
        },
        footer: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
          color: "#E1705D",
        },
        tableHeader: {
          fontSize: 10,
          bold: true,
          color: '#595d5f',
          alignment: 'center',
          margin: [0, 6, 0 , 0],
        },
          tableHeader2rows: {
          fontSize: 10,
          bold: true,
          color: '#595d5f',
          alignment: 'center'
        },
        tableCell: {
          fontSize: 10,
          color: '#595d5f'
        }
      };
    }
  },
  mounted() {
    console.log('report id', this.reportId)
    this.downloadPdf(this.reportId);
  },
  methods: {
    downloadPdf(reportId) {
      getKeywords(reportId).then((response) => {
        this.keywordList = response.data.keywords;
        //delete this part before google form upload
        this.keywordList.forEach((keyword) => {
          keyword.cost = Math.floor(Math.random() * 1000);
          keyword.conversions = Math.floor(Math.random() * 100);
          if (Math.random() > 0.5) {
            keyword.conversions = 0;
          }
          keyword.quality = Math.floor(Math.random() * 7);
        });
        //delete this part before google form upload
        pdfMake.fonts = {
          Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
          }
        };
        let documentDefinition = {
          content: this.generateContent(),
          pageSize: { width: 1920, height: 1080 },
          pageOrientation: 'landscape',
          pageMargins: [25, 20, 25, 60],
          styles: this.styles,
          defaultStyle: {
            font: 'Roboto'
          },
          footer: this.footerContent
        };
        pdfMake.createPdf(documentDefinition, null, null, pdfFonts).download("chart.pdf");
      });
    },
    getBase64Image(img) {
      console.log(img)
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      console.log(dataURL)
      return dataURL;
    },
    generateContent() {
      let content = [];
      content.push({
        image: this.getBase64Image(document.getElementById('first-image')),
        height: 595.28,  // width for A4 in landscape mode
        width: 841.89, // height for A4 in landscape mode
        absolutePosition: {x: 0, y: 0}
      });
      // add table with low quality filter
      for (let i = 0; i < this.keywordChunks(this.keywordsWithLowQuality).length; i++) {
        content.push({text: '', pageBreak: 'after'});
        content.push(...getKeywordsTableContent(this.keywordChunks(this.keywordsWithLowQuality)[i], this.totalCost , this.keywordsWithLowQualityTotal, 'Low-Quality Keywords (quality score less than 5)','on low QS keywords'));
      }
      // add table with 0 conversions and high cost filter
      console.log('average csost', this.averageCostPerConversion)
      for (let i = 0; i < this.keywordChunks(this.keywordsWithoutConversionsAndHighCost).length; i++) {
        content.push({text: '', pageBreak: 'after'});
        content.push(...getKeywordsTableContent(this.keywordChunks(this.keywordsWithoutConversionsAndHighCost)[i], this.totalCost , this.keywordsWithoutConversionsAndHighCostTotal, 'Non-converting keywords with high cost (>$' + this.averageCostPerConversion * 2 +')','on keywords that do not convert'));
      }
      // add table with high cost filter
      for (let i = 0; i < this.keywordChunks(this.keywordsWithConversionsAndHighCost).length; i++) {
        content.push({text: '', pageBreak: 'after'});
        content.push(...getKeywordsTableContent(this.keywordChunks(this.keywordsWithConversionsAndHighCost)[i], this.totalCost , this.keywordsWithConversionsAndHighCostTotal, 'Keywords with Cost per conversion too high(>$' + this.averageCostPerConversion * 2 +')','on keywords with a high CPA'));
      }
      // add table with Top Spending filter
      for (let i = 0; i < this.keywordChunks(this.topSpendingKeywords).length; i++) {
        content.push({text: '', pageBreak: 'after'});
        content.push(...getKeywordsTableContent(this.keywordChunks(this.topSpendingKeywords)[i], this.totalCost , this.topSpendingKeywordsTotal, 'Your 10 highest spending keywords','on the keywords that spend the most'));
      }
      content.push({text: '', pageBreak: 'after'});
      content.push({
        image: getLastImage(),
        height: 595.28,  // width for A4 in landscape mode
        width: 841.89, // height for A4 in landscape mode
        absolutePosition: {x: 0, y: 0}
      });
      return content;
    },
    footerContent(currentPage, pageCount) {
      if (currentPage === 1 || currentPage === pageCount) {
        return {};
      }
      return {
        columns: [
          {
            text: "AnalyzeSwift.com",
            style: "footer",
            width: "*",
          },
          {
            width: 70, // This is the total width of your three ellipses (3 * 20)
            canvas: [
              {type: "ellipse", x: 0, y: 10, color: "#FFC619", r1: 10, r2: 10},
              {type: "ellipse", x: 25, y: 10, color: "#E1705D", r1: 10, r2: 10},
              {type: "ellipse", x: 50, y: 10, color: "#E1705D", r1: 10, r2: 10},
            ],
            alignment: "right",
          },
        ],
        margin: [20, 20],
      };
    },
    keywordChunks(keywordList) {
      const chunkSize = 10;
      return Array(Math.ceil(keywordList.length / chunkSize)).fill([]).map((_, index) => {
        return keywordList.slice(index * chunkSize, (index + 1) * chunkSize);
      });
    }
  }
}
</script>