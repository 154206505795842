export const getKeywordsTableContent = (keywords, totalcost, listCost,  title, subtitle) => {
    return [
        getHeaderStack(title),
        getEmptySpace(),
        getSubHeaderTable(subtitle, totalcost, listCost),
        getDataTable(keywords)
    ];
};

export const getHeaderStack = (title) =>{
    return {
        table: {
            widths: ['*'], // 100% width
            body: [
                [
                    {
                        stack: [
                            {
                                text: "Search Ads - Keyword analysis",
                                style: "header",
                            },
                            {
                                text: title,
                                style: "header2",
                            },
                        ],
                        margin: [10, 0, 0, 0],
                        border: [true, false, false, false],
                    }
                ]
            ]
        },
        layout: {
            vLineWidth: function(i, node) {
                return 5; // Vertical line width of 1
            },
            vLineColor: function(i, node) {
                return '#E1705D'; // Vertical line color
            }
        }
    };
};
export const getEmptySpace = () =>{
    return {
        stack: [
            {
                text: '',  // Empty text
                margin: [0, 10, 0, 10],  // Top and bottom margin of 5px each to create a total space of 10px
            }
        ]
    };
};
export const getSubHeaderTable = (subtitle, totalcost, listCost) =>{
    let percentage =  totalcost !== 0 ? (listCost / totalcost) * 100 : 0;
    return {
        table: {
            widths: ['*'],
            body: [
                [
                    {
                        stack: [
                            {
                                text: listCost + ' € (' + percentage.toFixed(0) + ' %) INVESTED',
                                style: 'subHeaderCost',
                                alignment: 'center'
                            },
                            {
                                text: subtitle,
                                style: 'subHeader',
                                alignment: 'center'
                            }
                        ],
                        fillColor: '#E1705D'
                    }
                ]
            ]
        },
        layout: 'noBorders'
    };
};
export const getDataTable = (keywords) =>{
    let tableRows = keywords.map(item => {
        return [
            {text: item.keyword, style: 'tableCell'},
            {text: item.campaign, style: 'tableCell'},
            {text: item.adset, style: 'tableCell'},
            {text: item.averageCpc || 'N/A', style: 'tableCell'},
            {text: item.clicks, style: 'tableCell'},
            {text: item.impressions, style: 'tableCell'},
            {text: item.ctr || 'N/A', style: 'tableCell'},
            {text: item.cost, style: 'tableCell'},
            {text: item.conversions, style: 'tableCell'},
            {text: item.cost && item.conversions !== 0 ? (item.cost / item.conversions).toFixed(2) : 'N/A', style: 'tableCell'}, // Coût / Conv
            {text: item.quality || 'N/A', style: 'tableCell'}
        ];
    });
    tableRows.unshift([{text: 'Mot clé', style: 'tableHeader'}, {text: 'Campagne', style: 'tableHeader'}, {text: 'Groupe d\'annonces', style: 'tableHeader'}, {text: 'CPC moy.', style: 'tableHeader2rows'}, {text: 'Clics', style: 'tableHeader'}, {text: 'Imp.', style: 'tableHeader'}, {text: 'CTR', style: 'tableHeader'}, {text: 'Coût', style: 'tableHeader'}, {text: 'Conversions', style: 'tableHeader'}, {text: 'Coût / Conv', style: 'tableHeader2rows'}, {text: 'Niveau de qualité', style: 'tableHeader2rows'}]);
    return {
        table: {
            headerRows: 1,
            widths: ["15%", "15%", "15%", "6%", "6%", "7%", "6%", "6%", "9%", "7%", "8%"],
            body: tableRows
        },
        layout: {
            hLineWidth: function (i, node) {
                if (i === 1) return 2; // double border at the bottom of the header
                return 1; // single border for other rows
            },
            vLineWidth: function (i, node) {
                return 1; // single border for all columns
            },
            hLineColor: function (i, node) {
                return '#dadce0'; // change to desired color
            },
            vLineColor: function (i, node) {
                return '#dadce0'; // change to desired color
            }
        },
        margin: [0, 15],
    };
};