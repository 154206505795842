import axios from 'axios';
//const baseURL= 'http://localhost:3000'
const baseURL= 'https://analyzeswift-backend.onrender.com'

const googleInstance = axios.create({
    baseURL: baseURL,
    timeout: 90000,
    headers: {
        'Content-Type': 'application/json'
    }
})
export async function getClientList(accessToken) {
        let data = JSON.stringify({
            accessToken: accessToken,
            redirectUrl: import.meta.env.VITE_REDIRECT_URL
        })
        return googleInstance.post(baseURL + '/ads/ai/googleCustomer', data)
          .then(response => {
            return response
          })
}

export async function getKeywords(reportId) {
    let data = JSON.stringify({
        reportId: reportId
    })
    return googleInstance.post(baseURL + '/ads/ai/getKeywords', data)
        .then(response => {
            return response
        })
}

export async function fetchData(accountId, customerId, customerName, managerName, managerId) {
    let data = JSON.stringify({
        accountId: accountId,
        customerId: customerId,
        customerName: customerName,
        managerName: managerName,
        managerId: managerId
    })
    return googleInstance.post(baseURL + '/ads/ai/fetchData', data)
        .then(response => {
            return response
        })
}

export async function generatePdf(reportId) {
    let data = JSON.stringify({
        reportId: reportId,
        url: import.meta.env.VITE_URL
    })
    return googleInstance.post(baseURL + '/ads/ai/generatePdf', data, {
        responseType: 'blob'
    }).then(response => {
            return response
        })
}



