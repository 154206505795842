<template>
  <div
    class="loader m-auto">
    <script type="application/javascript" src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <lottie-player
        src="https://lottie.host/023f32fa-29df-4b49-a5c2-08686cdb0e91/SAvmEmpXjs.json"
        background="#ffffff"
        speed="1"
        style="width: 300px;
        height: 300px"
        loop autoplay direction="1" mode="normal" >
    </lottie-player>
  </div>
</template>

<script>

export default {
  name: 'LoadingIcon',
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {

  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
