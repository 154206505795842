<template>
  <div class="container-fluid py-4">
    <div class="container">
      <div class="social-auth">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"Googleauth",
  mounted() {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
    const options = {
      redirect_uri: import.meta.env.VITE_REDIRECT_URL,
      client_id: '659415254100-j34ffj8ro1vck8cok5s298q6rgrmfi24.apps.googleusercontent.com',
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/adwords'
      ].join(' '),
      state: '/',
    };

    const qs = new URLSearchParams(options);
    window.location = `${rootUrl}?${qs.toString()}`;
  }
}
</script>
<style>

.social-auth {
    max-width: 27rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.google-auth {
    background-color: #fff;
    border-radius: 5px;
    padding: 0.6rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.google-auth img {
    height: 4rem;
    margin-right: 1rem;
}
.google-auth span {
    font-size: 1.8rem;
}

.google-auth:hover {
    box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
}
</style>
