<template>
  <div class="container-fluid py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 m-auto">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">{{ title }}</h6>
              </div>
            </div>
            <loading-icon
                v-if="loading" />
            <div
              v-else
              class="card-body p-3">
              <div class="row">
                <template
                  v-for="(managerGroup, index) in clientList">
                  <div :key="index" class="col-md-12">
                    <h5>{{ managerGroup[0].client.customer.descriptive_name }}</h5>
                    <ul>
                      <li
                        v-for="(clientEntry, clientIndex) in managerGroup"
                        :key="clientIndex"
                        class="list-group">
                        <div class="form-check form-switch ps-0">
                          <input
                            :id="'flexSwitchCheckDefault'+clientIndex+index"
                            v-model="selectedClient"
                            :value="clientEntry"
                            name="flexSwitchCheckDefault"
                            class="ms-auto form-check-input"
                            type="radio">
                          <label
                            class="form-check-label ms-3 text-truncate w-80 mb-0"
                            :for="'flexSwitchCheckDefault'+clientIndex+index">
                            {{ clientEntry.clientName }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </template>
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm mb-0 col-4 ms-auto"
                    @click="generateKeywords">
                  Choose
                </button>
              </div>
            </div>
          </div>
          <div>
            <p class="float-end"><a href="https://analyzeswift.com/policy/" target="_blank" rel="noopener">Privacy Policy</a>&nbsp;| <a href="https://analyzeswift.com/terms-and-conditions/" target="_blank" rel="noopener">Terms of use </a></p>
          </div>
        </div>
      </div>
    </div>

    <google-generation ref="pdf-generator"/>
  </div>
</template>
<script>
import {fetchData, getClientList} from "@/apis/GoogleApi";
import Vue from "vue";
import LoadingIcon from "@/views/components/LoadingIcon.vue";
import GoogleGeneration from "@/views/Report/GoogleGeneration.vue";
export default {
  name:"GoogleCallback",
  components: {LoadingIcon, GoogleGeneration},
  data() {
    return {
      clientList: [],
      accessToken: '',
      selectedClient: null,
      accountId: '',
      loading: true,
      keywordsGenerated: false,
      accountsLoaded: false
    }
  },
  computed: {
    title() {
      if (this.loading) {
        if (this.accountsLoaded) {
          return 'Generating Keywords ...';
        } else {
          return 'Getting Google accounts ...';
        }
      } else {
        return 'Choose the account to be audited :';
      }
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.accessToken = urlParams.get('code');
    getClientList(this.accessToken).then((response) => {
      this.clientList = response.data.customers;
      this.accountId = response.data.id;
      this.loading = false;
      this.accountsLoaded = true
    }).catch((error) => {
      this.$router.push({ name: 'GoogleAuthenticate' });
      console.log(error)
    });
  },
  methods: {
    generateKeywords() {
      this.loading = true;
      const accountId = this.accountId;
      const clientName = this.selectedClient.clientName;
      const clientId = this.selectedClient.client.customer_client_link.client_customer.split('/')[1];
      const managerName = this.selectedClient.client.customer.descriptive_name;
      const managerId = this.selectedClient.client.customer.resource_name.split('/')[1];
      fetchData(accountId, clientId, clientName, managerName, managerId).then((response) => {
        this.loading = false;
        this.$refs['pdf-generator'].downloadPdf(response.data.reportId);
      }).catch((error) => {
        this.loading = false;
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
    }
  }
}
</script>
<style scoped>
.form-check-input:checked[type=checkbox], .form-check-input:checked[type=radio] {
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.img-google {
  width: 50px;
  height: auto;
  margin-bottom: 20px;
}
</style>
